export const SET_NAME = "SET_NAME";
export const SET_AGE = "SET_AGE";

export const REQUEST_POSTS = "REQUEST_POSTS";
export const GET_ALL_DEVICES = "GET_ALL_DEVICES";

export const SOCKET_NOTI_CONNECT = "SOCKET_NOTI_CONNECT";
export const SOCKET_NOTI_CLOSE = "SOCKET_NOTI_CLOSE";

export const SOCKET_CONNECT = "SOCKET_CONNECT";
export const SOCKET_CLOSE = "SOCKET_CLOSE";
export const SOCKET_GET = "SOCKET_GET";
export const SOCKET_SET = "SOCKET_SET";
export const SOCKET_SET_PAUSE = "SOCKET_SET_PAUSE";
export const SOCKET_SET_RESUME = "SOCKET_SET_RESUME";
export const SOCKET_SET_HISTORY = "SOCKET_SET_HISTORY";
export const SOCKET_UNSET_HISTORY = "SOCKET_UNSET_HISTORY";
export const SOCKET_SUBSCRIBE = "SOCKET_SUBSCRIBE";
export const SOCKET_UNSUBSCRIBE = "SOCKET_UNSUBSCRIBE";

export const TREE_SET = "TREE_SET";

export const DRAWING_SELECT_WIDGET = "DRAWING_SELECT_WIDGET";
export const DRAWING_CREATE = "DRAWING_CREATE";
export const DRAWING_HAS_WIDGET = "DRAWING_HAS_WIDGET";

export const SOCKET_STATUS = "SOCKET_STATUS";

export const NOTI_UPDATE = "NOTI_UPDATE"
