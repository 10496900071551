import auth from "../auth";

var socket = undefined;
var sendMessage = undefined;
var queue_data = [];
var subscribe_pool = {};
// var count = 0;
function queue(data) {
  queue_data.push(data);
}
function send() {
  if (queue_data.length > 0) {
    if (socket.readyState) {
      let data = queue_data[0];
      queue_data.shift();
      socket.send(data);
    }
  }
}
function updateValue(value, tag) {
  // console.log("updateValue",tag,value,subscribe_pool,subscribe_pool.hasOwnProperty(tag))
  if (subscribe_pool.hasOwnProperty(tag)) {
    if (subscribe_pool[tag].length > 0) {
      subscribe_pool[tag].forEach(function(task) {
        if (task.tag === tag) {
          // console.log(value,task)
          try {
            task.update(value, tag);
          } catch (e) {
            // console.log("update widget",e)
          }
        }
      });
    }
  } else {
    let data = { message: JSON.stringify({ type: "remove_tag", tag: tag }) };
    queue(JSON.stringify(data));
  }
}
export const socket_connect = (roomName, initial = [],history=false) => {
  // console.log("socket_connect")
  var hostname = window.location.protocol + "//" + window.location.host;
  if (window.DEBUG) {
    hostname = window.EndPoint;
  }
  let protocol = "http:"
  if(hostname.includes("http://")){
    hostname = hostname.split("http://")[1];
  }
  if(hostname.includes("https://")){
    hostname = hostname.split("https://")[1];
    protocol = "https:"
  }
  if (socket === undefined) {
    socket = new WebSocket(
      // "ws://" + hostname + "/ws/tag/" + auth.getToken() + "/"
      (protocol==="https:"?"wss://":"ws://") + hostname + "/ws/tag/1/",
      auth.getToken()
    );
  } else if (socket && socket.readyState !== 1) {
    socket = new WebSocket(
      // "ws://" + hostname + "/ws/tag/" + auth.getToken() + "/"
      (protocol==="https:"?"wss://":"ws://") + hostname + "/ws/tag/1/",
      auth.getToken()
    );
  }
  let tempRoom = [];
  let result = [];
  initial.forEach(function(item) {
    if (tempRoom.indexOf(item.tag) === -1) {
      tempRoom.push(item.tag);
      result.push(item);
    }
  });
  if (initial.length > 0) {
    let tags = [];
    result.forEach(function(item) {
      if (item.hasOwnProperty("tag")) {
        tags.push(item.tag);
      }
    });
    let data = { message: JSON.stringify({ type: "add_tags", tags: tags }) };
    queue(JSON.stringify(data));
  } else {
    let data = { message: JSON.stringify({ type: "add_tag", tag: roomName }) };
    queue(JSON.stringify(data));
  }
  socket.onopen = function(e) {};
  socket.onmessage = function(e) {
    var data = JSON.parse(e.data);
    var message = JSON.parse(data["message"]);
    var tag = message.tag;
    var value = message.value;
    // if (window.isNaN(parseFloat(message.value))) {
    //   value = message.value;
    // }else{
    //   value = parseFloat(message.value);
    // }
    if (message.type === "notify_tag") {
      updateValue(value, tag);
    }
  };
  sendMessage = setInterval(send, 100, this);
  return socket;
};
export const socket_pause_history = (template_id) =>{
  let data = { message: JSON.stringify({ type: "command", command: "pause", template_id: template_id }) };
  queue(JSON.stringify(data));
}
export const socket_resume_history = (template_id) =>{
  let data = { message: JSON.stringify({ type: "command", command: "resume", template_id: template_id }) };
  queue(JSON.stringify(data));
}
export const socket_connect_history = (roomName,template_id,start_time,end_time, initial = []) => {
  var hostname = window.location.protocol + "//" + window.location.host;
  if (window.DEBUG) {
    hostname = window.EndPoint;
  }
  let protocol = "http:"
  if(hostname.includes("http://")){
    hostname = hostname.split("http://")[1];
  }
  if(hostname.includes("https://")){
    hostname = hostname.split("https://")[1];
    protocol = "https:"
  }
  if (socket === undefined) {
    socket = new WebSocket(
      // "ws://" + hostname + "/ws/tag/" + auth.getToken() + "/"
      (protocol==="https:"?"wss://":"ws://") + hostname + "/ws/simulation/1/",
      auth.getToken()
    );
  } else if (socket && socket.readyState !== 1) {
    socket = new WebSocket(
      // "ws://" + hostname + "/ws/tag/" + auth.getToken() + "/"
      (protocol==="https:"?"wss://":"ws://") + hostname + "/ws/simulation/1/",
      auth.getToken()
    );
  }
  let tempRoom = [];
  let result = [];
  initial.forEach(function(item) {
    if (tempRoom.indexOf(item.tag) === -1) {
      tempRoom.push(item.tag);
      result.push(item);
    }
  });
  if (initial.length > 0) {
    let tags = [];
    result.forEach(function(item) {
      if (item.hasOwnProperty("tag")) {
        tags.push(item.tag);
      }
    });
    let data = { message: JSON.stringify({ type: "add_tags", tags: tags }) };
    queue(JSON.stringify(data));
  } else {
    let data = { message: JSON.stringify({ type: "add_tag", tag: roomName }) };
    queue(JSON.stringify(data));
  }
  // console.log({ type: "command", command: "start",template_id:template_id,st:start_time,et:end_time })
  let data = { message: JSON.stringify({ type: "command", command: "start",template_id:template_id,st:start_time,et:end_time }) };
  queue(JSON.stringify(data));
  socket.onopen = function(e) {};
  socket.onmessage = function(e) {
    var data = JSON.parse(e.data);
    var message = JSON.parse(data["message"]);
    var tag = message.tag;
    var value = message.value;
    // if (window.isNaN(parseFloat(message.value))) {
    //   value = message.value;
    // }else{
    //   value = parseFloat(message.value);
    // }
    if (message.type === "notify_tag") {
      updateValue(value, tag);
    }
  };
  sendMessage = setInterval(send, 100, this);
  return socket;
};
export const socket_close_for_history = () => {
  if (socket !== undefined) {
    clearInterval(sendMessage);
    socket.close();
  }
  return socket;
};
export const socket_close = () => {
  if (socket !== undefined) {
    clearInterval(sendMessage);
    socket.close();
    // subscribe_pool = {};
  }
  return socket;
};

export const socket_get = tag => {
  // console.log("get socket",tag)
};

export const socket_set = (tag, value) => {
  // console.log("socket_set",tag,value)
  if (subscribe_pool.hasOwnProperty(tag) === false) {
    if (tag === -1) {
      return;
    }
  }
  let data = {
    message: JSON.stringify({ type: "set_tag", tag: tag, value: value })
  };
  queue(JSON.stringify(data));
};
export const socket_get_tag = (tag) => {
  // console.log("subscribe_pool",tag,subscribe_pool)
  if (subscribe_pool.hasOwnProperty(tag) === false) {
    if (tag === -1) {
      return;
    } 
  }
  let data = { message: JSON.stringify({ type: "get_tag", tag: tag }) };
  queue(JSON.stringify(data));
};
export const socket_subscribe = (order,tag, update) => {
  
  if (subscribe_pool.hasOwnProperty(tag) === false) {
    if (tag === -1) {
      return;
    } else {
      subscribe_pool[tag] = [];
    }
  }
  // console.log("subscribe_pool2",subscribe_pool)
  // count += 1;
  subscribe_pool[tag].push({ id: order, tag: tag, update: update });
  let data = { message: JSON.stringify({ type: "add_tag", tag: tag }) };
  queue(JSON.stringify(data));
  // console.log("subscribe_pool",subscribe_pool)
  return order;
};

export const socket_unsubscribe = (tag, id) => {
  // console.log("socket_unsubscribe",tag,id,subscribe_pool)
  if (subscribe_pool.hasOwnProperty(tag) && id) {
    var result = [];
    subscribe_pool[tag].forEach(function(task) {
      if (task.id !== id) {
        result.push(task);
      }
    });
    subscribe_pool[tag] = result;
    // console.log("unsubscribe_pool",subscribe_pool)
  }
};
