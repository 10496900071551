import {
    SOCKET_NOTI_CONNECT,
    SOCKET_NOTI_CLOSE,
  } from '../actions'
  
import {socket_connect,socket_close} from '../services/socketNotification'

  const socketInitialState = {
    socket:null
  }
  
  export const socketNotiReducer = (state= socketInitialState,action)=>{
    switch (action.type) {
      case SOCKET_NOTI_CONNECT:
      let socket = socket_connect("1",action.payload)
      // console.log(socket)
      state={
        ...state,
        socket:socket
      }
      
      break;
      case SOCKET_NOTI_CLOSE:
      socket_close()
      state={
        ...state,
        socket:null
      }
      break;
      default:
    }
    return state;
  }
  