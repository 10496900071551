import {
    SOCKET_CONNECT,
    SOCKET_CLOSE,
    SOCKET_GET,
    SOCKET_SET,
    SOCKET_SET_HISTORY,
    SOCKET_UNSET_HISTORY,
    SOCKET_SET_PAUSE,
    SOCKET_SET_RESUME,
  } from '../actions'
  
import {socket_connect,socket_close,socket_get,socket_connect_history,socket_pause_history,socket_resume_history} from '../services/socket'

  const socketInitialState = {
    tags: [],
    history:false,
    history_config:undefined
      // {tag:"Meter_Plane_1.Volt"},{tag:"Meter_Plane_2.Volt"},{tag:"Meter_Plane_1.Current"},{tag:"Meter_Plane_2.Current"}]
  }
  
  export const socketReducer = (state= socketInitialState,action)=>{
    switch (action.type) {
      case SOCKET_CONNECT:
      state={
        ...state,
        tags:action.payload
      }
      if(state.history===false){
        socket_connect("3",state.tags)
      }else{
        socket_connect_history("3",state.history_config.template_id,state.history_config.st,state.history_config.et,state.tags)
      }
      break;
      case SOCKET_CLOSE:
      state={
        ...state
      }
      socket_close()
      break;
      case SOCKET_SET_PAUSE:
      state={
        ...state,
        history:true,
        history_config:action
      }
      socket_pause_history(action.payload.template_id)
      break;
      case SOCKET_SET_RESUME:
      state={
        ...state,
        history:true,
        history_config:action
      }
      socket_resume_history(action.payload.template_id)
      break;
      case SOCKET_SET_HISTORY:
      state={
        ...state,
        history:true,
        history_config:action
      }
      socket_close()
      socket_connect_history("3",action.payload.template_id,action.payload.st,action.payload.et,state.tags)
      break;
      case SOCKET_UNSET_HISTORY:
      state={
        ...state,
        history:false,
        history_config:undefined
      }
      socket_close()
      socket_connect("3",state.tags)
      break;
      case SOCKET_GET:
      state={
        ...state,
      }
      socket_get(state.onmessage)
      break;
      case SOCKET_SET:
      state={
        ...state,
        name:action.payload
      }
      break;
      default:
    }
    return state;
  }
  