import "antd/dist/antd.css";
import "react-toggle/style.css";
import "./styles/main.css";
import "./styles/myStyle.scss";

import "bpmn-js/dist/assets/diagram-js.css";
import "bpmn-js/dist/assets/bpmn-font/css/bpmn-embedded.css";

import React from "react";
import { HashRouter, Route, Switch } from "react-router-dom";
import ReactDOM from "react-dom";
import { createStore, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import { createLogger } from "redux-logger";
import Loadable from "react-loadable";

import reducer from "./reducers";
import auth from "./auth";

const loginPage = Loadable({
  loader: () =>
    import(/*webpackChunkName:"login.page"*/ "./components/User/login"),
  loading: () => null,
});
const MainPage = Loadable({
  loader: () => import(/*webpackChunkName:"main.page"*/ "./components/main"),
  loading: () => null,
});
const NotFoundPage = Loadable({
  loader: () => import(/*webpackChunkName:"page404.page"*/ "./pages/page404"),
  loading: () => null,
});

const middleware = [thunk];
if (process.env.NODE_ENV !== "production") {
  middleware.push(createLogger());
}

const store = createStore(reducer, applyMiddleware(...middleware));

const App = () => {
  const requireAuth = (nextState, replace) => {
    if (!auth.loggedIn()) {
      replace({
        pathname: "/login",
        state: { nextPathname: nextState.location.pathname },
      });
    }
  };
  return (
    <Provider store={store}>
      <HashRouter>
        <div style={{ margin: 0, height: "100%" }}>
          <Switch>
            <Route path="/login" component={loginPage} />
            <Route path="/" component={MainPage} onEnter={requireAuth} />
            <Route component={NotFoundPage} />
          </Switch>
        </div>
      </HashRouter>
    </Provider>
  );
};

ReactDOM.render(<App />, document.getElementById("root"));
