import { TREE_SET } from "../actions";

const treeInitialState = {
  tree: [],
  system: undefined,
  searchSystem: "",
  dashboard: undefined,
};

export const treeReducer = (state = treeInitialState, action) => {
  switch (action.type) {
    case TREE_SET:
      state = {
        ...state,
        tree: action.payload.tree,
        system: action.payload.system,
        searchSystem: action.payload.searchSystem,
        dashboard: action.payload.dashboard,
      };
      console.log("TREE_SET", state);
      break;
    default:
  }
  return state;
};
