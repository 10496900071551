import auth from "../auth";

var socket = undefined;
var subscribe_pool = {};
// var count = 0;
function updateValue(value, tag) {
  // console.log("updateValue",tag,value,subscribe_pool,subscribe_pool.hasOwnProperty(tag))
  if (subscribe_pool.hasOwnProperty(tag)) {
    if (subscribe_pool[tag].length > 0) {
      subscribe_pool[tag].forEach(function(task) {
        if (task.tag === tag) {
          // console.log(value,task)
          try {
            task.update(value, tag);
          } catch (e) {
            // console.log("update widget",e)
          }
        }
      });
    }
  }
}
export const socket_connect = (roomName, updateValue) => {
  // console.log("socket_connect")
  var hostname = window.location.protocol + "//" + window.location.host;
  if (window.DEBUG) {
    hostname = window.EndPoint;
  }
  let protocol = "http:"
  if(hostname.includes("http://")){
    hostname = hostname.split("http://")[1];
  }
  if(hostname.includes("https://")){
    hostname = hostname.split("https://")[1];
    protocol = "https:"
  }
  if (socket === undefined) {
    socket = new WebSocket(
      // "ws://" + hostname + "/ws/tag/" + auth.getToken() + "/"
      (protocol==="https:"?"wss://":"ws://") + hostname + "/ws/alert/"+roomName+"/",
      // auth.getToken()
    );
  } else if (socket && socket.readyState !== 1) {
    socket = new WebSocket(
      // "ws://" + hostname + "/ws/tag/" + auth.getToken() + "/"
      (protocol==="https:"?"wss://":"ws://") + hostname + "/ws/alert/"+roomName+"/",
      // auth.getToken()
    );
  }

  socket.onopen = function(e) {};
  socket.onmessage = updateValue;
  return socket;
};

export const socket_close = () => {
  if (socket !== undefined) {
    socket.close();
    // subscribe_pool = {};
  }
  return socket;
};