/**
 * Created by Toey on 9/12/2559.
 */
import $ from "jquery";
import { createHashHistory } from "history";

const history = createHashHistory();

window.DEBUG = process.env.NODE_ENV === "development" ? true : false;
window.EndPoint = "https://intelligentscada.com";
// window.EndPoint = "https://ictopenscada.ml";
// window.EndPoint = "https://scadaict.ml";
window.EndPoint2 = "http://intelligentscada.com:8083/";
// window.EndPoint = "https://192.168.2.220";

var hostname =
  window.location.protocol + "//" + window.location.host + "/restapi";
if (window.DEBUG) {
  hostname = window.EndPoint + "/restapi";
}

var user;
var err;

const auth = {
  login: function(userName, pass, cb) {
    cb = arguments[arguments.length - 1];
    if (localStorage.token) {
      if (cb) cb(true);
      this.onChange(true);
      return;
    }
    if (userName && pass) {
      pretendRequest(userName, pass, (res) => {
        if (res.authenticated) {
          localStorage.token = res.token;
          if (cb) cb(true);
          this.onChange(true);
        } else {
          err = res.msg;
          if (cb) cb(false);
          this.onChange(false);
        }
      });
    } else {
      err = "username & password is undefined";
      if (cb) cb(false);
      this.onChange(false);
    }
  },
  getToken: function() {
    return localStorage.token;
  },
  getError: function() {
    return err;
  },
  logout: function(cb) {
    $.ajax({
      async: false,
      url: hostname + "/logout/",
      type: "GET",
      dataType: "json",
      contentType: "application/json",
      headers: {
        authorization: "Token " + localStorage.token,
        accept: "application/json;odata=verbose",
      },
      success: function(results) {
        user = undefined;
        localStorage.clear();
        if (cb) cb();
        this.onChange(false);
        history.push("/login");
      }.bind(this),
      error: function(response) {
        user = undefined;
        localStorage.clear();
        history.push("/login");
      },
    });
  },
  loggedIn: function() {
    ///check loggedIn status
    return !!localStorage.token;
  },
  onChange: function() {},
  getUser: function() {
    $.ajax({
      async: false,
      url: hostname + "/users/userinfo/",
      type: "GET",
      dataType: "json",
      contentType: "application/json",
      headers: {
        authorization: "Token " + localStorage.token,
        accept: "application/json;odata=verbose",
      },
      success: function(output) {
        user = output.results[0];
      }.bind(this),
      error: function(response) {
        user = undefined;
        localStorage.clear();
        history.push("/login");
      },
    });
    return user;
  },
  getUserInfo: function() {
    return user || {};
  },
};

export default auth;

function pretendRequest(userName, pass, cb) {
  setTimeout(() => {
    $.ajax({
      async: false,
      url: hostname + "/api-token-auth/",
      type: "POST",
      dataType: "json",
      contentType: "application/json",
      headers: {
        accept: "application/json;odata=verbose",
      },
      data: JSON.stringify({
        username: userName,
        password: pass,
      }),
      success: function(data, status, xhr) {
        cb({
          authenticated: true,
          token: data.token,
        });
      }.bind(this),
      error: function(response) {
        var tx = "";
        var rspTaxt = response.responseText;
        var json = $.parseJSON(rspTaxt);
        if (json.non_field_errors) {
          for (var j = 0; json.non_field_errors[j]; ++j) {
            tx = tx + ", " + json.non_field_errors[j];
          }
        }
        try {
          tx = tx.substring(2);
        } catch (err) {
          tx = rspTaxt;
        }
        cb({
          authenticated: false,
          msg: tx,
        });
      },
    });
  }, 0);
}
