import {
  SET_NAME,
  SET_AGE
} from '../actions'

const userInitialState = {
  name: "username123",
  age: 20
}

export const userReducer = (state= userInitialState,action)=>{
  switch (action.type) {
    case SET_NAME:
    state={
      ...state,
      name:action.payload
    }
    break;
    case SET_AGE:
    state={
      ...state,
      age:action.payload
    }
    break;
    default:
  }
  return state;
}
