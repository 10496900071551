import { SOCKET_STATUS } from "../actions";
import { socket_status } from "../services/socketDeviceStatus";

const statusInitialState = { devices: [] };

export const socketStatusReducer = (state = statusInitialState, action) => {
  switch (action.type) {
    case SOCKET_STATUS:
      state = {
        ...state,
        devices: action.payload.devices
      };
      socket_status(state.devices);
      break;
      default:
  }
  return state;
};
