import { combineReducers } from "redux";

import { userReducer } from "./userReducer";
import { socketNotiReducer } from "./socketNotiReducer";
import { socketReducer } from "./socketReducer";
import { socketSubscribeReducer } from "./socketSubscribeReducer";
import { treeReducer  } from "./treeReducer";
import { drawingReducer } from "./drawingReducer";
import { alertNotificationReducer } from "./alertNotificationReducer";

import { socketStatusReducer } from "./socketStatusReducer";

const rootReducer = combineReducers({
  userReducer,
  socketReducer,
  socketNotiReducer,
  socketSubscribeReducer,
  treeReducer,
  drawingReducer,
  alertNotificationReducer,
  socketStatusReducer
});
export default rootReducer;
