var socket_stat_dev = undefined;
var send_mess_stat_dev = undefined;
var queue_data_sta_dev = [];
var status_device_pool = {};

function queue(data) {
  queue_data_sta_dev.push(data);
}

function send() {
  if (queue_data_sta_dev.length > 0) {
    if (socket_stat_dev.readyState) {
      let data = queue_data_sta_dev[0];
      queue_data_sta_dev.shift();
      socket_stat_dev.send(data);
    }
  }
}

function updateValue(device, value) {
  if (status_device_pool.devices.length > 0) {
    status_device_pool.devices.forEach((element, index, array) => {
      if (element.name === device) {
        element.status = value;
      }
    });
    status_device_pool.update(status_device_pool.devices);
  }
}

export const socket_status = (initial = []) => {
  var hostname = window.location.protocol + "//" + window.location.host;
  if (window.DEBUG) {
    hostname = window.EndPoint;
  }

  let hn_arr = hostname.split("//");
  socket_stat_dev = new WebSocket(
    (hn_arr[0] === "https:" ? "wss://" : "ws://") + hn_arr[1] + "/ws/device/1/"
  );

  let data = {
    message: JSON.stringify({ type: "get_devices", names: initial })
  };
  queue(JSON.stringify(data));
  socket_stat_dev.onopen = function(e) {};
  socket_stat_dev.onmessage = function(e) {
    var data = JSON.parse(e.data);
    var message = JSON.parse(data["message"]);
    if (message.type === "device") {
      updateValue(message.name, message.value);
    }
  };
  send_mess_stat_dev = setInterval(send, 100, this);
  return socket_stat_dev;
};

export const socket_status_device = (devices, update) => {
  status_device_pool["devices"] = devices;
  status_device_pool["update"] = update;
};

export const socket_status_close = () => {
  if (socket_stat_dev) {
    clearInterval(send_mess_stat_dev);
    socket_stat_dev.close();
  }
  return socket_stat_dev;
};
