import {
    SOCKET_SUBSCRIBE,
    SOCKET_UNSUBSCRIBE,
  } from '../actions'
  
import {socket_subscribe,socket_unsubscribe} from '../services/socket'

  const socketSubscribeInitialState = {
    id: undefined
  }
  
  export const socketSubscribeReducer = (state= socketSubscribeInitialState,action)=>{
    switch (action.type) {
      case SOCKET_SUBSCRIBE:
      state={
        ...state,
        id:socket_subscribe(action.payload.tag,action.payload.update)
      }
       
      break;
      case SOCKET_UNSUBSCRIBE:
      console.log("SOCKET_UNSUBSCRIBE")
      state={
        ...state,
        
      }
      socket_unsubscribe(action.payload.tag,action.payload.id)
      break;
      default:
    }
    return state;
  }
  