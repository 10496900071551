import {
    NOTI_UPDATE
  } from '../actions'
  

  const alertNotificationInitialState = {
    noti_count:null
  }
  
  export const alertNotificationReducer = (state= alertNotificationInitialState,action)=>{
    switch (action.type) {
      case NOTI_UPDATE:
      state={
        ...state,
        noti_count:action.payload
      }
      
      break;
    //   case NOTI_GET:
    //   state={
    //     ...state,
    //     socket:null
    //   }
    //   break;
      default:
    }
    return state;
  }
  