import {
  DRAWING_SELECT_WIDGET,
  DRAWING_CREATE
} from "../actions";

const drawingInitialState = {
  select_tag: undefined,
  drawing: undefined
};

export const drawingReducer = (state = drawingInitialState, action) => {
  let drawing;
  switch (action.type) {
    case DRAWING_CREATE:
      state = {
        ...state,
        drawing: action.payload
      };
      break;
    case DRAWING_SELECT_WIDGET:
      state = {
        ...state,
        select_tag: action.payload
      };
      drawing = state.drawing;
      if (drawing.state.editable) {
        drawing.setSelectWidgetCanvas(state.select_tag);
      } else {
        drawing.state.widgets_data.forEach(function(data) {
          document.getElementById(
            "test" + data.order + drawing.state.draw_key
          ).style.border = "";
          if (state.select_tag === data.tag) {
            document.getElementById(
              "test" + data.order + drawing.state.draw_key
            ).style.border = "solid 2px red";
          }
        });
      }
      break;
      default:
  }
  return state;
};
